import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export default {
  __name: 'MYearMonthPicker',
  props: {
    modelValue: {
      type: [String, Object, Number]
    },
    checkIndicator: {
      type: Boolean,
      default: true
    }
  },
  emits: ['confirm', 'update:modelValue'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var cols = [{
      values: [],
      displayValues: [],
      textAlign: 'right'
    }, {
      values: [],
      displayValues: [],
      textAlign: 'left'
    }];
    var yearNow = new Date().getFullYear();

    for (var year = yearNow + 2; year >= 1911; year--) {
      cols[0].values.push(year);
      cols[0].displayValues.push("".concat(year, "\u5E74"));
    }

    for (var month = 1; month <= 12; month++) {
      cols[1].values.push(month);
      cols[1].displayValues.push("".concat(month, "\u6708"));
    }

    return function (_ctx, _cache) {
      var _component_WheelPicker = _resolveComponent("WheelPicker");

      return _openBlock(), _createBlock(_component_WheelPicker, {
        class: "m-year-month-picker",
        cols: cols,
        modelValue: __props.modelValue,
        checkIndicator: __props.checkIndicator,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return _ctx.$emit('update:modelValue', $event);
        }),
        onConfirm: _cache[1] || (_cache[1] = function ($event) {
          return _ctx.$emit('confirm');
        })
      }, null, 8, ["modelValue", "checkIndicator"]);
    };
  }
};