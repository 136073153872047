import localForage from "localforage"

const storage = localForage.createInstance({
  driver: localForage.LOCALSTORAGE, // Force WebSQL; same as using setDriver()
  name: 'mukistore',
  version: 1.0,
  storeName: 'mukistore', // Should be alphanumeric, with underscores.
  description: 'app 設定儲存空間'
})

const TOP_STORAGE_NAME = 'TOP' // global
const AUTH_STORAGE_NAME = 'AUTH' // auth
const TEMP_MESSAGE_STORAGE_NAME = 'TEMP_MESSAGE' // 暫存訊息

export default storage
export {
  TOP_STORAGE_NAME,
  AUTH_STORAGE_NAME,
  TEMP_MESSAGE_STORAGE_NAME
}