/* unplugin-vue-components disabled */import { unref as _unref, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import InputSwitch from 'primevue/inputswitch';
export default {
  __name: 'MSwitch',
  props: {
    modelValue: Boolean,
    activeBg: String
  },
  emits: ['update:modelValue'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var cData = reactive({
      checked: false
    });
    watch(function () {
      return props.modelValue;
    }, function () {
      cData.checked = props.modelValue;
    }, {
      immediate: true
    });
    watch(function () {
      return cData.checked;
    }, function () {
      emit('update:modelValue', cData.checked);
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createBlock(_unref(InputSwitch), {
        class: "m-switch",
        modelValue: _unref(cData).checked,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return _unref(cData).checked = $event;
        }),
        style: _normalizeStyle({
          '--m-switch-active-color': __props.activeBg || null
        })
      }, null, 8, ["modelValue", "style"]);
    };
  }
};