import mukiConst from '@/js/const';

/**
 * 顧客
 * @type {Customer}
 */
export const CUSTOMER_1 = {
    id: 0,
    customerPhone: '0912345678',
    name: '莊曉明',
    customerType: mukiConst.customerType.NEW,
    birthday: '1994-01-01',
}

export const CUSTOMER_2 = {
    id: 1,
    customerPhone: '0912345678',
    name: '王曉明',
    customerType: mukiConst.customerType.NEW,
    birthday: '1994-01-01',
}

export const CUSTOMER_3 = {
    id: 2,
    customerPhone: '0912345678',
    name: '吳曉明',
    customerType: mukiConst.customerType.NEW,
    birthday: '1994-01-01',
}

export const CUSTOMER_4 = {
    id: 3,
    customerPhone: '0912345678',
    name: '李曉明',
    customerType: mukiConst.customerType.NEW,
    birthday: '1994-01-01',
}

export const CUSTOMERS = {
    CUSTOMER_1,
    CUSTOMER_2,
    CUSTOMER_3,
    CUSTOMER_4,
}

/**
 * 事件
 * @type {OrderEvent}
 */
export const EVENT_1 = {
    id: 1,
    orderStatus: mukiConst.eventType.WAIT_SERVICE,
    customerPhone: '0912345678',
    customerCode: '556',
    employeeId: 31,
    name: '王曉明',
    customerType: mukiConst.customerType.NEW,
    orderDate: '2023-03-17T00:00:00+08:00',
    contactAt: '',
    startAt:[ 9, 15],
    endAt:[11, 35],
    consultId: 0,
    consultName: '丁小雨',
    doctorId: 1,
    doctorName: '王醫檢',
    content:'眼袋外開、眼袋外開中臉拉提眼袋外開眼',
    memo:'',
    needToContact: 0,
    color: '#06799D'
}

/**
 * @type {OrderEvent}
 */
export const EVENT_2 = {
    id: 2,
    orderStatus: mukiConst.eventType.ABSCENT,
    customerPhone: '0912345678',
    customerCode: '556',
    employeeId: 33,
    name: '王曉明2',
    customerType: mukiConst.customerType.CONFRIMED,
    orderDate: '2023-03-04T00:00:00+08:00',
    contactAt: '',
    startAt:[],
    endAt:[],
    consultId: 0,
    consultName: '丁小雨',
    doctorId: 1,
    doctorName: '王醫檢',
    content:'眼袋外開、眼袋外開中臉拉提眼袋外開眼',
    memo:'',
    needToContact: 1,
    color: '#114EF8'
}

/**
 * @type {OrderEvent}
 */
export const EVENT_3 = {
    id: 3,
    orderStatus: mukiConst.eventType.ABSCENT,
    customerPhone: '0912345678',
    customerCode: '556',
    employeeId:35,
    name: '王曉明3',
    customerType: mukiConst.customerType.NEW,
    orderDate: '2023-03-04T00:00:00+08:00',
    contactAt: '',
    startAt:[9, 15],
    endAt:[11, 35],
    consultId: 2,
    consultName: 'Fanny',
    doctorId: null,
    doctorName: null,
    content:'中臉拉提+眼袋外開眼',
    memo:'',
    needToContact: 0,
    color: '#114EF8'
}

/**
 * @type {OrderEvent}
 */
export const EVENT_4 = {
    id: 4,
    orderStatus: mukiConst.eventType.CANCEL,
    customerPhone: '0912345678',
    customerCode: '556',
    employeeId: 37,
    name: '王曉明4',
    customerType: mukiConst.customerType.NEW,
    orderDate: '2023-03-04T00:00:00+08:00',
    contactAt: '',
    startAt:[9, 15],
    endAt:[11, 35],
    consultId: 2,
    consultName: 'Fanny',
    doctorId: null,
    doctorName: null,
    content:'中臉拉提+眼袋外開眼',
    memo:'',
    needToContact: 0,
    color: '#114EF8'
}

/**
 * @type {OrderEvent}
 */
export const EVENT_5 = {
    id: 5,
    orderStatus: mukiConst.eventType.SERVING,
    customerPhone: '0912345678',
    customerCode: '556',
    employeeId: 38,
    name: '王曉明5',
    customerType: mukiConst.customerType.NEW,
    orderDate: '2023-03-21T00:00:00+08:00',
    contactAt: '',
    startAt:[9, 15],
    endAt:[11, 35],
    consultId: 5,
    consultName: '漢米爾頓',
    doctorId: null,
    doctorName: null,
    content:'中臉拉提+眼袋外開眼',
    memo:'',
    needToContact: 0,
    color: '#114EF8'
}


/**
 * 鎖定時段事件
 * @type {LockEvent}
 */
export const LOCK_EVENT_1 = {
    id: 91,
    date: '2023-02-16T00:00:00+08:00',
    startAt: [9, 15],
    endAt: [12, 35],
    lock: 1
}

/**
 * 醫生班表事件
 * @type {DoctorHoursEvent}
 */
export const DOCTOR_HOURS_EVENT_1 = {
    id: 81,
    hoursType: mukiConst.hoursType.MORNING,
    date: '2023-02-20T00:00:00+08:00',
    startAt: [9, 15],
    endAt: [12, 35],
    doctors: ['蔡時安', '葉文中'],
}

/**
 * @type {DoctorHoursEvent}
 */
export const DOCTOR_HOURS_EVENT_2 = {
    id: 82,
    hoursType: mukiConst.hoursType.MORNING,
    date: '2023-02-21T00:00:00+08:00',
    startAt: [9, 15],
    endAt: [12, 35],
    doctors: ['蔡時安', '葉文中','蔡時安', '葉文中','蔡時安', '葉文中'],
}

/**
 * @type {DoctorHoursEvent}
 */
export const DOCTOR_HOURS_EVENT_3 = {
    id: 84,
    hoursType: mukiConst.hoursType.NOON,
    date: '2023-02-21T00:00:00+08:00',
    startAt: [12, 35],
    endAt: [16, 35],
    doctors: ['葉文中','蔡時安', '葉文中','蔡時安', '葉文中'],
}

/**
 * @type {DoctorHoursEvent}
 */
export const DOCTOR_HOURS_EVENT_4 = {
    id: 85,
    hoursType: mukiConst.hoursType.NIGHT,
    date: '2023-02-21T00:00:00+08:00',
    startAt: [16, 35],
    endAt: [20, 35],
    doctors: ['蔡時安', '葉文中','蔡時安', '葉文中','蔡時安', '葉文中'],
}


export const EVENTS = {
    EVENT_1,
    EVENT_2,
    EVENT_3,
    EVENT_4,
    EVENT_5,

    LOCK_EVENT_1,

    DOCTOR_HOURS_EVENT_1,
    DOCTOR_HOURS_EVENT_2,
    DOCTOR_HOURS_EVENT_3,
    DOCTOR_HOURS_EVENT_4,
}
