import { ref, reactive, onMounted, nextTick } from "vue";
export default {
  props: {
    gap: {
      type: String,
      default: '15px'
    }
  },
  setup: function setup() {
    var cData = reactive({});
    onMounted(function () {});
    return {
      cData: cData
    };
  }
};