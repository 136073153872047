export function useEventPopupWord() {
  var addWordRef = ref(null);

  var addWord = function addWord(val) {
    var textarea = addWordRef.value.$el.querySelector('textarea');
    pasteWord(textarea, val);
  };

  var pasteWord = function pasteWord(element, val) {
    if (!element) return;
    if (element.type !== 'textarea') return;
    var textarea = element;
    var length = textarea.value.length;
    var selectionStart = textarea.selectionStart || length;
    var selectionEnd = textarea.selectionEnd || length;
    var myPrefix = textarea.value.substring(0, selectionStart);
    var mySuffix = textarea.value.substring(selectionEnd);
    textarea.value = myPrefix + val + mySuffix;
    textarea.dispatchEvent(new Event('input'));
    textarea.focus();
    nextTick(function () {
      textarea.setSelectionRange(selectionStart + val.length, selectionStart + val.length);
    });
  };

  return {
    addWordRef: addWordRef,
    addWord: addWord
  };
}