/* unplugin-vue-components disabled */import __unplugin_components_0 from 'C:/docker/118_meijia/frontend/src/components/RoundCloseBtn.vue';
import { unref as _unref, renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue";
import Dialog from 'primevue/dialog';
export default {
  __name: 'MModal',
  props: {
    closeBtn: Boolean,
    visible: Boolean,
    // 高度填滿
    heightFill: Boolean
  },
  emits: ['init', 'update:visible', 'hide', 'show'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var cData = reactive({
      visible: props.visible
    });

    function close() {
      emit('update:visible', false);
    } // 外部


    watch(function () {
      return props.visible;
    }, function () {
      cData.visible = props.visible;
    }); // 內部

    watch(function () {
      return cData.visible;
    }, function () {
      if (!cData.visible) {
        document.body.classList.remove('p-overflow-hidden');
        emit('hide');
      } else {
        emit('show');
      }
    });

    function checkBodyOverflow() {
      if (document.documentElement.clientHeight < document.documentElement.scrollHeight) {
        document.body.classList.add('is-body-overflow');
      } else {
        document.body.classList.remove('is-body-overflow');
      }
    }

    function clickSelf() {
      console.log('clickSelf');
    }

    onMounted(function () {
      nextTick(function () {
        checkBodyOverflow();
      });
    });
    return function (_ctx, _cache) {
      var _component_RoundCloseBtn = __unplugin_components_0;

      return _openBlock(), _createBlock(_unref(Dialog), {
        visible: _unref(cData).visible,
        "onUpdate:visible": _cache[0] || (_cache[0] = function ($event) {
          return _unref(cData).visible = $event;
        }),
        class: _normalizeClass(["m-modal", {
          'm-modal_h-fill': __props.heightFill,
          'm-modal_has-close-btn': __props.closeBtn
        }]),
        "show-header": false,
        maximizable: true,
        modal: true,
        onShow: _cache[1] || (_cache[1] = function ($event) {
          return _ctx.$emit('update:visible', true);
        }),
        onHide: _cache[2] || (_cache[2] = function ($event) {
          return _ctx.$emit('update:visible', false);
        })
      }, {
        default: _withCtx(function () {
          return [_renderSlot(_ctx.$slots, "default", {
            close: close
          }), __props.closeBtn ? (_openBlock(), _createBlock(_component_RoundCloseBtn, {
            key: 0,
            class: "m-modal__close-btn",
            onClick: close
          })) : _createCommentVNode("", true)];
        }),
        _: 3
      }, 8, ["visible", "class"]);
    };
  }
};