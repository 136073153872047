/* unplugin-vue-components disabled */import "core-js/modules/es.number.constructor.js";
import { unref as _unref, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export default {
  __name: 'BaseSpacer',
  props: {
    h: {
      type: [Number, String],
      default: 0
    },
    rwdVal: [Array]
  },
  setup: function setup(__props) {
    var props = __props;
    var windowInnerWidth = ref(window.innerWidth);

    function onResize() {
      windowInnerWidth.value = window.innerWidth;
    }

    window.addEventListener('resize', onResize);
    var rwdVal = computed(function () {
      var w = windowInnerWidth.value;

      if (Array.isArray(props.rwdVal) && props.rwdVal.length === 4) {
        return "".concat(appRwdVal(props.rwdVal[0], props.rwdVal[1], props.rwdVal[2], props.rwdVal[3]), "px");
      } else {
        return props.h;
      }
    });
    onUnmounted(function () {
      window.removeEventListener('resize', onResize);
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        class: "base-spacer",
        style: _normalizeStyle("height:".concat(_unref(rwdVal), ";"))
      }, null, 4);
    };
  }
};