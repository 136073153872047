import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["v-lined-list", {
      'v-lined-list_solid-line': $props.solidLine,
      'v-lined-list_no-tail': $props.noTail,
      'v-lined-list_first-item-align-top': $props.firstItemAlignTop,
      'v-lined-list_board': $props.board
    }]),
    style: _normalizeStyle({
      '--v-lined-list-item-v-padding': $props.noPadding ? 0 : $props.vPadding ? $props.vPadding : null,
      '--v-lined-list-border-color': $props.lineColor ? $props.lineColor : null,
      '--v-lined-list-item-inset': $props.inset ? $props.inset : null
    })
  }, [_renderSlot(_ctx.$slots, "default")], 6);
}