import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  class: "list-display-item"
};
var _hoisted_2 = {
  class: "row"
};
var _hoisted_3 = {
  class: "col-6"
};
var _hoisted_4 = {
  key: 0,
  class: "list-display-item__title"
};
var _hoisted_5 = {
  class: "col-6"
};
var _hoisted_6 = {
  key: 0,
  class: "list-display-item__content"
};
import { ref, reactive, onMounted, nextTick } from "vue";
export default {
  __name: 'ListDisplayItem',
  props: {
    title: null,
    content: null
  },
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_ctx.$slots['title'] || __props.title ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_renderSlot(_ctx.$slots, "title", {}, function () {
        return [_createTextVNode(_toDisplayString(__props.title), 1)];
      })])) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_5, [_ctx.$slots['content'] || __props.content ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_renderSlot(_ctx.$slots, "content", {}, function () {
        return [_createTextVNode(_toDisplayString(__props.content), 1)];
      })])) : _createCommentVNode("", true)])])]);
    };
  }
};