import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return $setup.thisNum > 0 ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass(["muki-num-badge", {
      'is-over-99': $setup.thisNum > 99
    }]),
    style: _normalizeStyle({
      '--size': $props.size ? $props.size : null,
      '--bg-color': $props.bgColor ? $props.bgColor : null
    })
  }, [_createElementVNode("span", {
    class: _normalizeClass({
      'fz-trans-10px': parseInt($setup.thisNum) > 10
    })
  }, _toDisplayString($setup.thisNum), 3)], 6)) : _createCommentVNode("", true);
}