/* unplugin-vue-components disabled */import "core-js/modules/es.number.constructor.js";
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "scroller-static"
};
var _hoisted_2 = {
  class: "scroller-static"
};
import { ref, reactive, onMounted, nextTick } from "vue";
export default {
  __name: 'ContentScroller',
  props: {
    num: {
      type: Number,
      default: 0
    },
    // body 可否scroll
    bodyScroll: {
      type: Boolean,
      default: false
    },
    // 自動撐高
    fillHeight: {
      type: Boolean,
      default: false
    },
    // 子層有fillHeightScroller
    hasFillHeightScroller: Boolean,
    hasTab: Boolean,
    bodyClass: [String]
  },
  setup: function setup(__props) {
    var refRoot = ref(null);
    var parents;
    onMounted(function () {
      parents = $(refRoot.value).parents('.page__content, .page-container, .p-dialog-content');
      parents.addClass('content-scroller-parent');
    });
    onMounted(function () {
      if (!$('.content-scroller').length) {
        parents.removeClass('content-scroller-parent');
      }
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        ref_key: "refRoot",
        ref: refRoot,
        class: _normalizeClass(["content-scroller", {
          'content-scroller_body-scroll': __props.bodyScroll,
          'content-scroller_fill-height': __props.fillHeight,
          'content-scroller_has-fill-height-scroller': __props.hasFillHeightScroller,
          'content-scroller_has-tab': __props.hasTab
        }])
      }, [_createElementVNode("div", _hoisted_1, [_renderSlot(_ctx.$slots, "top")]), _createElementVNode("div", {
        class: _normalizeClass(["scroller-body", __props.bodyClass])
      }, [_renderSlot(_ctx.$slots, "body")], 2), _createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "bottom")])], 2);
    };
  }
};