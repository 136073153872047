import { computed, reactive } from "vue"
import creteStoreUtil from '@/store/store-util'
import storage, { AUTH_STORAGE_NAME } from '@/js/storage'

const defaultState = {
  token: '',
  // 自己的employee_id
  employeeId: '',
  name: '',
  brandId: 0,
  branchId: 0,
  branchCode: '',
  branches: [],
  loginId: '',
  memo: '',
  code: '',
  level: '',
  color: '',
  isLogined: false,
  limitGroupCode: '',
  allPermission: 0,
}

const { init, getData, setData, resetData, initState } = creteStoreUtil({
  defaultState
})

export default {
  namespaced: true,

  state: () => ({
  }),
  getters: {
    getData,
    isPermissionOnlyHQ_MAG: (state) => {
      return ['HQ_MAG'].includes(state.limitGroupCode) 
    },
    isPermissionGroupNoHQ_ANG: (state) => {
      return ['BR_MAG', 'BR_ANG', 'HQ_MAG'].includes(state.limitGroupCode) 
    },
    isPermissionGroup: (state) => {
      return ['BR_MAG', 'BR_ANG', 'HQ_MAG', 'HQ_ANG'].includes(state.limitGroupCode) 
    },
    isCome: (state) => {
      return state.brandId === 1
    },
  },
  actions: {
    login({ commit }, data) {
      data.isLogined = true
      commit('setData', data)
    },
    update({ commit }, data) {
      commit('setData', data)
    },
    visitor({ commit }, data) {
      commit('setData', data)
    },
    logout({ commit }, data) {
      commit('resetData')
    },
    init,
    // init({commit}){
    //     return new Promise((resolve, reject)=>{

    //         commit('initState')

    //         storage.getItem( AUTH_STORAGE_NAME , (err, value) => {
    //             if ( value ) {

    //                 commit('setData', value);
    //             }

    //             resolve()
    //         });
    //     })
    // },
  },
  mutations: {
    initState,
    resetData,
    setData,
  },
}
