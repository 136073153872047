import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { reactive } from '@vue/reactivity';
import VueQRCodeComponent from 'vue-qrcode-component';
export default {
  __name: 'Qrcode',
  props: {
    text: String
  },
  setup: function setup(__props) {
    var props = __props;
    var cData = reactive({
      dataUrl: null
    });

    function onDataUrlChange(dataUrl) {
      cData.dataUrl = dataUrl;
    }

    return function (_ctx, _cache) {
      return _openBlock(), _createBlock(_unref(VueQRCodeComponent), {
        class: "m-qrcode",
        text: __props.text,
        onChange: onDataUrlChange
      }, null, 8, ["text"]);
    };
  }
};