import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export default {
  __name: 'BaseButton',
  props: {
    type: {
      type: String,
      default: ''
    },
    disabled: Boolean,
    tag: {
      type: String,
      default: 'a'
    },
    href: String,
    variant: String,
    // 高度
    h: {
      type: [String],
      default: ''
    },
    // fz
    fz: {
      type: [String],
      default: ''
    },
    // radius
    radius: [String, Boolean],
    // pill 樣式
    pill: Boolean,
    // login register頁用
    loginRegister: Boolean,
    // 陰影
    shadow: Boolean,
    // 透明
    textOpacity: Boolean,
    // borderWidth
    borderWidth: String,
    // 大小
    lg: Boolean,
    // block 、 inline
    inline: Boolean,
    color: String
  },
  setup: function setup(__props) {
    var props = __props;
    return function (_ctx, _cache) {
      return _openBlock(), _createBlock(_resolveDynamicComponent(__props.tag), {
        type: __props.type,
        href: __props.href ? __props.href : 'javascript:;',
        class: _normalizeClass(["button", [{
          'button_pill': __props.pill || null,
          'button_inline': __props.inline || null,
          'button_lg': __props.lg || null,
          'button_shadow': __props.shadow || null,
          'button_radius': __props.radius || __props.radius === '' || null
        }, __props.color ? "button_".concat(__props.color) : null, __props.variant ? "button_".concat(__props.variant) : null]]),
        disabled: __props.disabled || null,
        style: _normalizeStyle({
          '--button-border-wdith': __props.borderWidth || null,
          '--button-height': __props.h || null,
          '--button-fz': __props.fz || null,
          '--button-radius': __props.radius || null
        })
      }, {
        default: _withCtx(function () {
          return [_renderSlot(_ctx.$slots, "default")];
        }),
        _: 3
      }, 8, ["type", "href", "disabled", "class", "style"]);
    };
  }
};