import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "layout layout_system"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_SystemSider = _resolveComponent("SystemSider");

  var _component_Gotop = _resolveComponent("Gotop");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_SystemSider), _renderSlot(_ctx.$slots, "default"), _createVNode(_component_Gotop, {
    exclude: ['/add-announcement']
  })]);
}