/* unplugin-vue-components disabled */import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export default {
  __name: 'BaseContentToggle',
  props: {
    modelValue: {
      type: [Boolean, String, Number],
      default: false
    },
    // 點外面就自動關閉
    closeOnClickOutside: Boolean,
    toggleBtn: String,
    // selector、dom
    // 點到該元素不要執行關閉的動作
    preventCloseIdentifyer: String
  },
  emits: ['update:modelValue'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var cData = reactive({
      show: props.modelValue
    });
    var refRoot = ref(null);

    function hide() {
      $(refRoot.value).slideUp();
    }

    function show() {
      $(refRoot.value).slideDown();
    }

    function toggle() {
      cData.show = !cData.show;
    }

    function init() {
      if (!props.modelValue) {
        if (refRoot.value) {
          refRoot.value.style.display = 'none';
        }
      }
    }

    function onBodyClick(evt) {
      if (props.modelValue && props.closeOnClickOutside) {
        var isSelf = $(refRoot.value).is(evt.target);
        var isChild = $(refRoot.value).has(evt.target).length;
        var ignoreElement = props.preventCloseIdentifyer ? $(props.preventCloseIdentifyer) : '';
        var isIgnoreElement = ignoreElement ? ignoreElement.is(evt.target) : false; // 點空白處，自動關閉

        if (!isSelf && !isChild && !isIgnoreElement) {
          emit('update:modelValue', false);
        }
      }
    }

    function checkChildComponentUpdate() {
      var formItems = refRoot.value.querySelectorAll('.form-item');
      formItems.forEach(function (element) {
        if (element.dispatchEvent) {
          element.dispatchEvent(new CustomEvent('toUpdate'));
        }
      });
    }

    watch(function () {
      return props.modelValue;
    }, function () {
      cData.show = props.modelValue;
    });
    watch(function () {
      return cData.show;
    }, function () {
      if (cData.show) {
        show();
        checkChildComponentUpdate();
      } else {
        hide();
      }
    });
    onMounted(function () {
      init();

      if (props.toggleBtn) {
        $(props.toggleBtn).click(function () {
          toggle();
        });
      }

      document.documentElement.addEventListener('click', onBodyClick);
    });
    onUnmounted(function () {
      document.documentElement.removeEventListener('click', onBodyClick);
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        ref_key: "refRoot",
        ref: refRoot,
        class: "base-content-toggle"
      }, [_renderSlot(_ctx.$slots, "default")], 512);
    };
  }
};