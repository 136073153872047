/* unplugin-vue-components disabled */import __unplugin_components_0 from 'C:/docker/118_meijia/frontend/src/components/NakeSelect.vue';
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { Branch } from "@/js/services/baseService";
export default {
  __name: 'YuanSelect',
  setup: function setup(__props) {
    var store = useStore();
    var cData = reactive({
      branches: computed(function () {
        return store.state.auth.branches.map(function (value) {
          return {
            name: value.name,
            value: _.toNumber(value.id)
          };
        });
      }).value
    });
    var branchId = ref(store.state.auth.branchId);
    watch(branchId, function (value) {
      Branch.info(value).then(function (response) {
        store.dispatch('auth/update', {
          brandId: response.brand_id,
          branchId: response.id,
          branchCode: response.code
        }).then(function () {
          window.location = '/';
        });
      });
    });
    return function (_ctx, _cache) {
      var _component_NakeSelect = __unplugin_components_0;

      return _openBlock(), _createBlock(_component_NakeSelect, {
        modelValue: _unref(branchId),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return _isRef(branchId) ? branchId.value = $event : null;
        }),
        placeholder: "清選擇",
        options: _unref(cData).branches,
        style: {
          "margin-right": "19px"
        },
        "scroll-height": "500px",
        onChange: _cache[1] || (_cache[1] = function () {})
      }, null, 8, ["modelValue", "options"]);
    };
  }
};