/* unplugin-vue-components disabled */import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { scrollContentCanLoadMore } from '@/js/functions';
export default {
  __name: 'SimpleBed',
  props: {
    // 監聽scroll
    listenScroll: Boolean
  },
  emits: ['scrollEnd'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var refRoot = ref(null);
    var refScrollContent = ref(null);
    onMounted(function () {
      if (props.listenScroll) {
        scrollContentCanLoadMore(refScrollContent.value, function () {
          emit('scrollEnd');
        });
      }
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        class: "simple-bed",
        ref_key: "refRoot",
        ref: refRoot
      }, [_createElementVNode("div", {
        class: "simple-bed__w-control",
        ref_key: "refScrollContent",
        ref: refScrollContent
      }, [_renderSlot(_ctx.$slots, "default")], 512)], 512);
    };
  }
};