import { ref, reactive, onMounted, nextTick } from "vue";
export default {
  props: {
    color: {
      default: '#06799D',
      type: String
    }
  },
  setup: function setup(props) {
    var cData = reactive({});
    onMounted(function () {});
    return {
      cData: cData
    };
  }
};