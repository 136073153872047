import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "input-group-control__seperator"
};
var _hoisted_2 = ["onClick", "innerHTML"];
export default {
  __name: 'InputGroupControl',
  props: {
    inline: Boolean,

    /**
     * @property {*} cols[0].width
     * @property {*} cols[0].value
     * @property {String} cols[0].class - 要加的class名稱
     * @property {Boolean} cols[0].active - 是否為active樣式
     * @property {Function} cols[0].onClick - onclick callback
     */
    cols: Array
  },
  setup: function setup(__props) {
    function width(value) {
      if ('string' == typeof value) {
        return value;
      } else {
        return value + 'px';
      }
    }

    function handleClick(item, event) {
      if ('function' === typeof item.onClick) {
        item.onClick({
          item: item,
          originEvent: event
        });
      }
    }

    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["input-group-control", [{
          'input-group-control_inline': __props.inline || null
        }]])
      }, [_renderSlot(_ctx.$slots, "default"), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.cols, function (item, idx) {
        return _openBlock(), _createElementBlock(_Fragment, {
          key: idx
        }, [idx !== 0 ? (_openBlock(), _createElementBlock("div", _hoisted_1)) : _createCommentVNode("", true), _createElementVNode("div", {
          class: _normalizeClass(["input-group-control__item", [{
            'input-group-control__item_active': item.active
          }, item.class]]),
          style: _normalizeStyle({
            width: item.width ? width(item.width) : ''
          }),
          onClick: function onClick($event) {
            return handleClick(item, $event);
          },
          innerHTML: item.value
        }, null, 14, _hoisted_2)], 64);
      }), 128))], 2);
    };
  }
};