/* unplugin-vue-components disabled */import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import 'v-calendar/dist/style.css';
import { Calendar, DatePicker } from 'v-calendar';
export default {
  __name: 'MCalendar',
  props: {
    modelValue: {
      default: new Date()
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var cData = reactive({});
    var refDatePicker = ref(null);
    onMounted(function () {});
    return function (_ctx, _cache) {
      return _openBlock(), _createBlock(_unref(DatePicker), {
        ref_key: "refDatePicker",
        ref: refDatePicker,
        mode: "date",
        class: "m-calendar",
        "model-value": __props.modelValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return _ctx.$emit('update:modelValue', $event);
        })
      }, null, 8, ["model-value"]);
    };
  }
};