import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "list-icon-item"
};
var _hoisted_2 = {
  class: "list-icon-item__append"
};
import { ref, reactive, onMounted, nextTick } from "vue";
import { calcValignCenterDiff } from '@/js/functions';
export default {
  __name: 'ListIconItem',
  props: {
    // preprend 垂直置中1em
    prependValignCenter: Boolean,
    // style
    iconColor: String
  },
  setup: function setup(__props) {
    var props = __props;
    var cData = reactive({
      prependPdt: 0
    });
    var refPrepend = ref(null);
    var refMain = ref(null);
    onMounted(function () {
      if (props.prependValignCenter) {
        cData.prependPdt = calcValignCenterDiff(refPrepend.value, refMain.value) + 'px';
      }
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        class: "list-icon-item__prepend",
        ref_key: "refPrepend",
        ref: refPrepend,
        style: _normalizeStyle({
          'padding-top': cData.prependPdt || 0,
          '--list-icon-item-icon-color': __props.iconColor || null
        })
      }, [_renderSlot(_ctx.$slots, "prepend")], 4), _createElementVNode("div", {
        class: "list-icon-item__main",
        ref_key: "refMain",
        ref: refMain
      }, [_renderSlot(_ctx.$slots, "default")], 512), _createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "append")])]);
    };
  }
};