import { computed, reactive } from "vue"
import creteStoreUtil from '@/store/store-util'
import { Order, Room } from "@/js/services/baseService"

const defaultState = {
  hints: []
}

const { init, getData, setData, resetData, initState } = creteStoreUtil({
  defaultState
})

export default {
  namespaced: true,
  state: () => ({
    hints: [],
    rooms: []
  }),
  getters: {
    hintsIdList: (state) => {
      return state.hints.reduce((acc, cur) => {
        return {
          ...acc,
          [cur.id]: cur
        }
      }, {})
    }
  },
  actions: {
    // 取得日曆資料
    getHints(context, payload) {
      Order.hints().then((res) => {
        context.commit('setData', {
          hints: res
        })
      })
    },
    // 取得日曆資料
    getRooms(context, payload) {
      Room.get({
        show_cancel_room: 1
      }).then((res) => {
        context.commit('setData', {
          rooms: res.map(item => ({...item, name: item.room_name }))
        })
      })
    },
    init,
  },
  mutations: {
    initState,
    resetData,
    setData,
  },
}
