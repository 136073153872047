/* unplugin-vue-components disabled */export default {
  props: {},
  setup: function setup() {
    var store = useStore();
    var cData = reactive({});
    onMounted(function () {});
    return {
      store: store,
      cData: cData
    };
  }
};