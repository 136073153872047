import "core-js/modules/es.function.name.js";
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "page-header"
};
var _hoisted_2 = {
  class: "row align-items-center"
};
var _hoisted_3 = {
  class: "col",
  style: {
    "padding-right": "0"
  }
};
var _hoisted_4 = {
  class: "col-auto",
  style: {
    "padding-left": "0"
  }
};
var _hoisted_5 = {
  class: "action-group my-1"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_YuanSelect = _resolveComponent("YuanSelect");

  var _component_UserRole = _resolveComponent("UserRole");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "left")]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_YuanSelect, {
    class: "inpaeg-header-yuan",
    "plugin-config": {
      'panelClass': 'inpaeg-header-yuan-dropdown'
    }
  }), _createVNode(_component_UserRole, {
    color: $setup.store.state.auth.color
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($setup.store.state.auth.name.substr(0, 1)), 1)];
    }),
    _: 1
  }, 8, ["color"])])])])]);
}