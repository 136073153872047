/* unplugin-vue-components disabled */import __unplugin_components_0 from 'C:/docker/118_meijia/frontend/src/components/WheelPicker.vue';
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { conditionAvailableTimeData } from '@/js/utils';
export default {
  __name: 'MTimePicker',
  props: {
    cols: Array,
    maxTime: {
      type: Array,
      default: function _default() {
        return [23, 59];
      }
    },
    minTime: {
      type: Array,
      default: function _default() {
        return [0, 0];
      }
    },
    modelValue: {
      type: Array,
      default: function _default() {
        return [0, 0];
      }
    },
    disabledHours: {
      type: Function,
      default: function _default() {
        return [];
      }
    },
    disabledMinutes: {
      type: Function,
      default: function _default(h) {
        return [];
      }
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var cols = ref([{
      values: [],
      displayValues: [],
      textAlign: 'right'
    }, {
      values: [':'],
      displayValues: [':'],
      width: '17px',
      divider: true
    }, {
      values: [],
      displayValues: [],
      textAlign: 'left'
    }]);

    function updateCols(data) {
      // 取得時間陣列
      var _conditionAvailableTi = conditionAvailableTimeData(data),
          hArr = _conditionAvailableTi.hArr,
          mArr = _conditionAvailableTi.mArr;

      var hDisplayValues = hArr.map(function (value) {
        return value < 10 ? "0".concat(value) : value;
      });
      var mDisplayValues = mArr.map(function (value) {
        return value < 10 ? "0".concat(value) : value;
      });
      cols.value[0].values = hArr;
      cols.value[0].displayValues = hDisplayValues;
      cols.value[2].values = mArr;
      cols.value[2].displayValues = mDisplayValues;
    }

    updateCols({
      maxTime: props.maxTime,
      minTime: props.minTime,
      nowTime: props.modelValue,
      disabledHours: props.disabledHours,
      disabledMinutes: props.disabledMinutes
    });
    return function (_ctx, _cache) {
      var _component_WheelPicker = __unplugin_components_0;

      return _openBlock(), _createBlock(_component_WheelPicker, {
        class: "m-time-picker",
        cols: _unref(cols),
        "model-value": __props.modelValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return _ctx.$emit('update:modelValue', $event);
        })
      }, null, 8, ["cols", "model-value"]);
    };
  }
};