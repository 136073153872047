/* unplugin-vue-components disabled */import "core-js/modules/es.number.constructor.js";
import { unref as _unref, renderSlot as _renderSlot, mergeProps as _mergeProps, createElementVNode as _createElementVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import Driver from 'driver.js/dist/driver.min.js';
import 'driver.js/dist/driver.min.css';
import { uuidv4 } from '@/js/utils';
import { createPopper, preventOverflow, flip, offset, computeStyles, applyStyles, detectOverflow } from '@popperjs/core';
export default {
  __name: 'Popper',
  props: {
    // 顯示
    show: Boolean,
    // 當hideWatch為false時，show要跟著為false
    hideWatch: Boolean,
    clickStopPropagation: Boolean,
    // 可點擊target來開關popper
    targetToggle: Boolean,
    zIndex: Number,
    // 點擊外部自動關閉
    closeOnClickOutside: {
      type: Boolean,
      default: false
    },
    forceCloseOnClickOutside: {
      type: Boolean,
      default: false
    },
    // backdrop
    backdrop: {
      type: Boolean,
      default: false
    },
    // popper目標 - 修改此參數會重新create popper
    target: [String, Object],
    // popper跟隨目標 - 修改此目標只會移動現有popper 位置
    followTarget: [String, Object],
    popperConfig: Object
  },
  emits: ['init', 'update:show', 'onBackdropClick'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var router = useRouter();
    var refRoot = ref(null);
    var v4id = uuidv4();
    var cData = reactive({
      popperInstance: '',
      popperHeight: '',
      _show: false
    });
    var driverPopper;
    var driverTarget;
    var config = {
      placement: 'bottom',
      onFirstUpdate: function onFirstUpdate() {},
      modifiers: [preventOverflow, flip, {
        name: 'offset',
        options: {
          offset: [0, 0]
        }
      }]
    };

    if (props.backdrop) {
      driverPopper = new Driver({
        opacity: 0.3,
        padding: 0,
        animate: true,
        allowClose: false,
        overlayClickNext: false,
        onDeselected: function onDeselected() {},
        onHighlightStarted: function onHighlightStarted() {},
        onHighlighted: function onHighlighted(Element) {
          var overlay = $(Element.overlay.node);
          overlay.on('click', function (e) {});
        }
      });
      driverTarget = new Driver({
        opacity: 0.3,
        padding: 0,
        animate: true,
        allowClose: false,
        overlayClickNext: false
      });
    }

    function onclick(e) {
      if (props.clickStopPropagation) {
        e.stopPropagation();
        e.preventDefault();
      }
    }
    /**
     * Popper沒有動態更改位置的功能
     * 故手動新增
     */


    function checkFollow() {
      var toFollow = $(props.followTarget);
      var instance = cData.popperInstance;
      var popperEl = instance.state.elements.popper;
      var toFollowPos = toFollow.position();
      var popperElPow = $(popperEl).position();
      var popperElH = $(popperEl).outerHeight();
      var newPopperBottom = toFollowPos.top + popperElH;
      var windowBottom = window.pageYOffset + window.innerHeight;
      var gap = 15;
      var popperStyle = window.getComputedStyle(popperEl);
      var popperMatrix = new WebKitCSSMatrix(popperStyle.webkitTransform);
      var popperTranslateY = popperMatrix.m42;
      var newPopperTop = newPopperBottom > windowBottom - gap ? windowBottom - gap - popperElH : toFollowPos.top;
      $(popperEl).stop().animate({
        // 要考慮到popper原有的translateY
        top: newPopperTop - popperTranslateY
      });
    }

    function updatePopper() {
      if (cData.popperInstance && cData.popperInstance.update) {
        cData.popperInstance.update();
      }
    }

    function show() {
      if (!$(props.target).length) return;

      if (!cData.popperInstance) {
        create();
      }

      if (props.backdrop) {
        setTimeout(function () {
          driverTarget.highlight($(props.target)[0]);
          driverPopper.highlight(refRoot.value);
        }, 100);
      }

      updatePopper();
      refRoot.value.setAttribute('data-show', '');
      cData.popperHeight = $(refRoot.value).outerHeight() + 'px';
    }

    function hide() {
      refRoot.value.removeAttribute('data-show');

      if (props.backdrop) {
        driverPopper.reset();
        driverTarget.reset();
      }

      cData.popperHeight = '';
    }

    function create() {
      var target = $(props.target);

      if (!target.length) {
        return;
      } // destroy before create new one


      if (cData.popperInstance) {
        cData.popperInstance.destroy();
      }

      var newConfig = $.extend(true, {}, config, props.popperConfig || {});
      var instance = createPopper(target[0], refRoot.value, newConfig);

      if (instance) {
        cData.popperInstance = instance;
      }

      if (props.targetToggle) {
        $(props.target).unbind('click', targetClick).on('click', targetClick);
      } // config更新


      watch(function () {
        return props.popperConfig;
      }, function () {
        var newConfig = $.extend(true, {}, config, props.popperConfig || {});
        cData.popperInstance.setOptions(newConfig);
      }); // force popper update

      setTimeout(function () {
        updatePopper();
      }, 0);
    }

    function bodyOnclick(e) {
      var target = $(props.target); // popper打開時一定會有target

      if (!target.length) return; // 不是點擊target

      if (!target.is(e.target) && !target.has(e.target).length // 目標
      && !$(refRoot.value).is(e.target) && !$(refRoot.value).has(e.target).length // 自己 
      && !$('.swal2-container').is(e.target) && !$('.swal2-container').has(e.target).length // 彈跳視窗
      ) {
        /**
         * 通常情況下，點擊到畫面上另一個popper，這個也會是有相關的內容
         * 故不視為點擊外部
         */
        if (!$(e.target).closest('.popper').length && $('.popper[data-show]').length == 1 && props.closeOnClickOutside) {
          cData._show = false;
        } // 強制指定點即到外面就關掉


        if (props.forceCloseOnClickOutside) {
          cData._show = false;
        }
      } // 點擊backdrop


      if ($(e.target).closest('#driver-page-overlay').length) {
        emit('onBackdropClick');
      }
    }

    function targetClick() {
      if (cData._show) {
        cData._show = false;
      } else {
        cData._show = true;
      }
    }

    function checkShow() {
      if (cData._show) {
        show();
      } else {
        hide();
      }
    }
    /**
     * Popper動態更改位置的功能
     */


    var updatePopperOnceFlag = false;
    watch(function () {
      return props.followTarget;
    }, function () {
      // 初次執行先update popper讓套件抓取最新環境資料
      if (!updatePopperOnceFlag) {
        updatePopperOnceFlag = true;

        if (cData.popperInstance) {
          cData.popperInstance.update();
          setTimeout(function () {
            checkFollow();
          }, 0);
        }
      } else {
        checkFollow();
      }
    }); // 目標target是否修改了

    watch(function () {
      return props.target;
    }, function () {
      create();
    }); // 顯示變化

    watch(function () {
      return props.show;
    }, function () {
      cData._show = props.show;
    });
    watch(function () {
      return cData._show;
    }, function () {
      emit('update:show', cData._show);
      checkShow();
    }); // 偵測hidewatch

    watch(function () {
      return props.hideWatch;
    }, function () {
      if (!props.hideWatch) {
        cData._show = false;
      }
    }); // 路由切換時popper沒關，則要關掉

    watch(function () {
      return router && router.currentRoute.value.path;
    }, function () {
      if (cData._show) {
        cData._show = false;
      }
    });
    onMounted(function () {
      create(); // initial value

      cData._show = props.show; // check show

      $('body').on('click', bodyOnclick);
    });
    onUnmounted(function () {
      $('body').unbind('click', bodyOnclick);

      if (cData.popperInstance && 'function' === typeof cData.popperInstance.destroy) {
        cData.popperInstance.destroy();
      }
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createBlock(_Teleport, {
        to: "body"
      }, [_createElementVNode("div", _mergeProps({
        ref_key: "refRoot",
        ref: refRoot,
        class: ["popper", ["popper-".concat(_unref(v4id))]],
        style: {
          '--popper-zindex': __props.zIndex || null,
          '--popper-height': _unref(cData).popperHeight || null
        }
      }, _ctx.$attrs, {
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return onclick($event);
        })
      }), [_renderSlot(_ctx.$slots, "default")], 16)]);
    };
  }
};