/* unplugin-vue-components disabled */import "core-js/modules/es.number.constructor.js";
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue";
import _imports_0 from '@/assets/images/icon/alert.svg';
import _imports_1 from '@/assets/images/icon/success.svg';
import _imports_2 from '@/assets/images/icon/question.svg';
var _hoisted_1 = {
  key: 0,
  src: _imports_0,
  alt: "",
  srcset: ""
};
var _hoisted_2 = {
  key: 1,
  src: _imports_1,
  alt: "",
  srcset: ""
};
var _hoisted_3 = {
  key: 2,
  src: _imports_2,
  alt: "",
  srcset: ""
};
var _hoisted_4 = {
  key: 3,
  class: "mj-type-icon__text"
};
import mukiConst from '@/js/const';
export default {
  __name: 'EventTypeIcon',
  props: {
    type: [Number, String, Array],
    text: String,
    showText: Boolean
  },
  setup: function setup(__props) {
    var props = __props;
    var cData = reactive({
      type: []
    });
    var orderSmsStatus = mukiConst.orderSmsStatus;
    var displayText = computed(function () {
      var result = '';

      if (props.type == orderSmsStatus.CANCELED) {
        result = '取消赴約';
      }

      if (props.type == orderSmsStatus.CONFIRMED) {
        result = '確認赴診';
      }

      if (props.type == orderSmsStatus.NOT_YET) {
        result = '尚未回覆';
      }

      return result;
    });
    watch(function () {
      return props.type;
    }, function () {
      if (!Array.isArray(props.type)) {
        cData.type = [props.type];
      } else {
        cData.type = props.type;
      }
    }, {
      immediate: true
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["mj-type-icon", [__props.type || __props.type == 0 ? "mj-type-icon_".concat(__props.type) : null]])
      }, [_unref(cData).type.indexOf(_unref(orderSmsStatus).CANCELED) > -1 ? (_openBlock(), _createElementBlock("img", _hoisted_1)) : _createCommentVNode("", true), _unref(cData).type.indexOf(_unref(orderSmsStatus).CONFIRMED) > -1 ? (_openBlock(), _createElementBlock("img", _hoisted_2)) : _createCommentVNode("", true), _unref(cData).type.indexOf(_unref(orderSmsStatus).NOT_YET) > -1 ? (_openBlock(), _createElementBlock("img", _hoisted_3)) : _createCommentVNode("", true), __props.showText || __props.text ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(__props.text || _unref(displayText)), 1)) : _createCommentVNode("", true)], 2);
    };
  }
};