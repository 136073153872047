import "core-js/modules/es.function.name.js";
import { appRwdVal } from '@/js/utils';

function registerDirectives(VueAppInstance) {
  /**
   * v-set-height
   * 使用js設定填滿高度
   */
  VueAppInstance.directive('fill-height-fixed', {
    /**
     *
     * @param {*} el
     * @param {Object} binding -
     * @param {Number} minus - 要扣除的高度
     */
    mounted: function mounted(el, binding) {
      if (el) {
        var rect = el.getBoundingClientRect();
        var minus = binding.value && binding.value.minus || 0; // set height for autoscroll

        $(el).css('height', window.innerHeight - rect.top - minus);
      }
    }
  }); // cleave js

  VueAppInstance.directive('cleave', {
    mounted: function mounted(el, binding) {
      el.cleave = new Cleave(el, binding.value || {});
    },
    updated: function updated(el) {
      var event = new Event('input', {
        bubbles: true
      });
      setTimeout(function () {
        el.value = el.cleave.properties.result;
        el.dispatchEvent(event);
      }, 100);
    }
  });
  /**
   * v-width
   *
   * rwd 的寬度設定
   */

  VueAppInstance.directive('width', {
    /**
     *
     * @param {*} el
     * @param {Number|String} binding - 預設為px
     */
    mounted: function mounted(el, binding) {
      function MemoryLeakTest() {
        this.name = MemoryLeakTest;
      } // TODO: 移除MemoryLeakTestFlag


      var MemoryLeakTestFlag = new MemoryLeakTest();
      var config = binding.value;
      var isArray = Array.isArray(config);
      var prevWindowWidth = window.innerWidth;

      function run() {
        if (!el || !binding) return;

        if (Array.isArray(config) && config.length === 4) {
          el.style.width = "".concat(appRwdVal(config[0], config[1], config[2], config[3]), "px");
        } else {
          if (typeof config == 'string') el.style.width = config;else el.style.width = "".concat(config, "px");
        }
      }

      function onResize() {
        if (window.innerWidth !== prevWindowWidth) {
          run();
        }

        prevWindowWidth = window.innerWidth;
      }

      el._vue_directive_listener = onResize;

      if (el) {
        run();
        window.addEventListener('resize', onResize);
      }
    },
    updated: function updated(el) {},
    unmounted: function unmounted(el, binding, vnode, prevVnode) {
      if (el._vue_directive_listener) {
        window.removeEventListener('resize', el._vue_directive_listener);
        el._vue_directive_listener = null;
      }
    }
  });
  /**
   * v-fill-height
   *
   * 利用 flex 特性達成自動填滿高度
   */

  VueAppInstance.directive('fill-height', {
    /**
    * @param {*} el
    * @param {Object} binding - 設定
    * @param {Object} binding.autoMaxHeight - 設定限高
    * @param {Object} binding.autoMaxHeightMinus - 設定限高要排除的部分
    */
    mounted: function mounted(el, binding) {
      var config = binding.value || {};
      var autoMaxHeight = config.autoMaxHeight || false;
      var autoMaxHeightMinus = config.autoMaxHeightMinus || 0;
      var viewHeight = window.innerHeight;
      window.$ = $;

      if (el) {
        var parent = $(el).parent();
        if (parent.length) parent.css({
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '100%'
        });
        $(el).css('flex-grow', 1);
        $(el).css('min-height', 0); // 設定限高

        if (autoMaxHeight) {
          var maxHeight = viewHeight - $(el).position().top;

          if (autoMaxHeightMinus) {
            if (typeof autoMaxHeightMinus == 'number') {
              maxHeight = maxHeight - autoMaxHeightMinus;
            } else {
              $(autoMaxHeightMinus).each(function (idx, el) {
                maxHeight = maxHeight - $(el).outerHeight();
              });
            }
          }

          $(el).css('max-height', maxHeight);
        }
      }
    },
    updated: function updated(el) {}
  });
  /**
     * v-active
     *
     * active class
     */

  VueAppInstance.directive('active', {
    /**
     *
     * @param {*} el
     * @param {Number|String} binding - 預設為px
     */
    mounted: function mounted(el, binding) {
      if (el) $(el).click();
    },
    updated: function updated(el) {}
  });
}

export { registerDirectives };