/**
 * @type {Customer}
 */
export const CUSTOMER = {
    id: null,
    customerPhone: null,
    name: null,
    customerType: null,
    birthday: null // (YYYY-MM-DD)
}


/**
 * @type {OrderEvent}
 */
export const ORDER_EVENT = {
  id: null,
  orderStatus:null,
  customerPhone:null,
  customerCode:null,
  employeeId:null,
  name:null,
  customerType:null,
  orderDate:null,
  contactAt:null,
  startAt: [],
  endAt:[],
  consultId:null,
  consultName:null,
  doctorId:null,
  doctorName:null,
  content:null,
  memo:null,
  needToContact:null,
  color:null,
  consult:{
    id: null,
    color: null,
    name: null,
  },
  doctor:{
    id: null,
    color: null,
    name: null,
  }, 
  hints: [], 
  assistants: [],
  stepsMap:[], 
  deleteEmployeeFormat: null,
  isNoSms: null
}


/**
 * @type {FullcalendarEvent}
 */
export const FULL_CALENDAR_EVENT = {
    id: null,
    title: null,
    start: null,
    end: null,
    extendedProps: {
        ...ORDER_EVENT
    }
}



/**
 * @type {LockEvent}
 */
export const LOCK_EVENT = {
    id: null,
    date: null,
    startAt: [],
    endAt: [],
    lock: null,
    employees: [],
    rooms: [],
    lock_target: 0,
    memo: '',
}

/**
 * @type {DoctorHoursEvent}
 */
export const DOCTOR_WORK_HOURS_EVENT = {
    id: null,
    hoursType: null,
    date: null,
    startAt: [],
    endAt: [],
    doctors: []
}

export default {
    CUSTOMER,
    FULL_CALENDAR_EVENT,
    ORDER_EVENT,
    LOCK_EVENT,
    DOCTOR_WORK_HOURS_EVENT
}