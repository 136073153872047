/* unplugin-vue-components disabled */import __unplugin_components_5 from 'C:/docker/118_meijia/frontend/src/components/form/FormItem.vue';
import __unplugin_components_4 from 'C:/docker/118_meijia/frontend/src/components/ConnectedInputGroup.vue';
import __unplugin_components_3 from 'C:/docker/118_meijia/frontend/src/components/MDropdownMenu.vue';
import __unplugin_components_2 from 'C:/docker/118_meijia/frontend/src/components/MCalendar.vue';
import __unplugin_components_1 from 'C:/docker/118_meijia/frontend/src/components/BaseSpacer.vue';
import __unplugin_components_0 from 'C:/docker/118_meijia/frontend/src/components/form/MInput.vue';
import "core-js/modules/es.string.pad-start.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.concat.js";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue";
export default {
  __name: 'MDateRange',
  props: {
    start: [String, Object],
    end: [String, Object],
    variant: String,
    // gray
    startPlaceholder: {
      type: String,
      default: '起始日期'
    },
    endPlaceholder: {
      type: String,
      default: '結束日期'
    }
  },
  setup: function setup(__props) {
    var props = __props;

    function keep2Digit(val) {
      return val.toString().padStart(2, "0");
    }

    function formateDate(dateType) {
      var year = dateType.getFullYear();
      var month = keep2Digit(dateType.getMonth() + 1);
      var date = keep2Digit(dateType.getDate());
      return "".concat(year, "-").concat(month, "-").concat(date);
    }

    var cData = reactive({
      allowRender: false,
      dateStartFormate: computed(function () {
        if (!props.start) {
          return;
        }

        return formateDate(props.start);
      }),
      dateEndFormate: computed(function () {
        if (!props.end) {
          return;
        }

        return formateDate(props.end);
      })
    });
    onMounted(function () {
      setTimeout(function () {
        cData.allowRender = true;
      }, 300);
    });
    return function (_ctx, _cache) {
      var _component_MInput = __unplugin_components_0;

      var _component_BaseSpacer = __unplugin_components_1;

      var _component_MCalendar = __unplugin_components_2;

      var _component_m_dropdown_menu = __unplugin_components_3;

      var _component_ConnectedInputGroup = __unplugin_components_4;

      var _component_FormItem = __unplugin_components_5;

      return _openBlock(), _createBlock(_component_FormItem, {
        class: _normalizeClass(["m-date-range", [__props.variant ? "m-date-range_".concat(__props.variant) : null]])
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_ConnectedInputGroup, null, {
            left: _withCtx(function () {
              return [_createVNode(_component_m_dropdown_menu, {
                "drop-config": {
                  classes: 'date-range-drop'
                }
              }, {
                menu: _withCtx(function (slotProp) {
                  return [_createVNode(_component_BaseSpacer, {
                    h: "7px"
                  }), _unref(cData).allowRender ? (_openBlock(), _createBlock(_component_MCalendar, {
                    key: 0,
                    "model-value": __props.start,
                    "onUpdate:modelValue": function onUpdateModelValue($event) {
                      slotProp.instance.close();

                      _ctx.$emit('update:start', $event);
                    }
                  }, null, 8, ["model-value", "onUpdate:modelValue"])) : _createCommentVNode("", true)];
                }),
                default: _withCtx(function () {
                  return [_createVNode(_component_MInput, {
                    radius: "",
                    readonly: "",
                    h: "41px",
                    placeholder: __props.startPlaceholder,
                    "model-value": _unref(cData).dateStartFormate
                  }, null, 8, ["placeholder", "model-value"])];
                }),
                _: 1
              })];
            }),
            right: _withCtx(function () {
              return [_createVNode(_component_m_dropdown_menu, {
                "drop-config": {
                  classes: 'date-range-drop'
                }
              }, {
                menu: _withCtx(function (slotProp) {
                  return [_createVNode(_component_BaseSpacer, {
                    h: "7px"
                  }), _unref(cData).allowRender ? (_openBlock(), _createBlock(_component_MCalendar, {
                    key: 0,
                    "model-value": __props.end,
                    "onUpdate:modelValue": function onUpdateModelValue($event) {
                      slotProp.instance.close();

                      _ctx.$emit('update:end', $event);
                    }
                  }, null, 8, ["model-value", "onUpdate:modelValue"])) : _createCommentVNode("", true)];
                }),
                default: _withCtx(function () {
                  return [_createVNode(_component_MInput, {
                    radius: "",
                    readonly: "",
                    h: "41px",
                    placeholder: __props.endPlaceholder,
                    "model-value": _unref(cData).dateEndFormate
                  }, null, 8, ["placeholder", "model-value"])];
                }),
                _: 1
              })];
            }),
            _: 1
          })];
        }),
        _: 1
      }, 8, ["class"]);
    };
  }
};