const CONST_BASE = {
  locale: {
    EN: 'en',
    TW: 'tw',
  },
  // 驗證
  csrToken: {
    INVALID: -99,
  },
  token: {
    INVALID: -98,
  },
  baseUrl:{
    DEVELOP: 'https://megasystem-dev-api.muki001.com/',
    MASTEr: 'https://megasystem-api.muki001.com/',
  },
  gender: {
    MALE: 0,
    FEMALE: 1,
  },
  // 品牌
  brand: {
    // 美加
    MJ: 2,
    // 康博
    KB: 1,
  },
  // 班別
  hoursType: {
    MORNING: 0,
    NOON: 1,
    NIGHT: 2,
  },
  // 人員種類
  personnelType: {
    // 醫生
    DOCTOR: 28,
    // 諮詢師
    CONSULT: 25,
    // 護士
    NURSE: 29,
    // 芳療師
    AROMATHERAPIST: 3,
  },
  // 客戶種類
  customerType: {
    // 當月壽星 (蛋糕)
    BIRTHDAY_STAR: 0,
    // 新客 (紅星星)
    NEW: 1,
    // 客戶資料卡不完整
    ALERT: 2,
    // 客戶備註
    DIFFICULT: 3,
  },
  // 客戶種類
  customerTypeIcon: {
    // 當月壽星 (蛋糕)
    BIRTHDAY_STAR: '@/assets/images/icon/customer-role/star.svg',
    // 新客 (紅星星)
    NEW: '@/assets/images/icon/customer-role/star.svg',
    // 注意 (驚嘆號)
    ALERT: '@/assets/images/icon/customer-role/star.svg',
    // 奧客 (黑星星)
    DIFFICULT: '@/assets/images/icon/customer-role/star.svg',
  },
  // 預約的SMS狀態
  orderSmsStatus:{
    // 尚未
    NOT_YET: 0,
    // 確認赴診
    CONFIRMED: 1,
    // 取消改約
    CANCELED: 2,
  },
  /**
   * TODO: 目前api只有 orderStatus 這幾種狀態，無法對應設計稿畫面。已請PM確認
   */
  // 預約狀態
  orderStatus: {
    // 待服務
    WAIT_SERVICE: 1,
    // 服務中
    SERVING: 2,
    // 已完成
    COMPLETE: 3,
    // 未到
    ABSCENT: 4,
    // 已取消
    CANCEL: 5,
  },
  bookStatus: {
    CANCEL: 0,
    CONFRIMED: 1,
    NO_RESPONSE: 2,
    PENDING: 3,
  },
  // 預約狀態
  eventType: {
    // 待服務
    WAIT_SERVICE: 1,
    // 服務中
    SERVING: 2,
    // 已完成
    COMPLETE: 3,
    // 未到
    ABSCENT: 4,
    // 取消
    CANCEL: 5,
    // 已確認
    CONFRIMED: 6,
    // 無回應
    NO_RESPONSE: 7,
    // 待約
    PENDING: 8,
  },
  // 預約時間限制
  bookTimeCondition: {
    minTime: [5, 0],
    maxTime: [23, 59],
  },
  // 是否顯示刪除的預約
  showDelete: {
    YES: 1,
    NO: 0
  },
  // 日曆
  calendar:{
    slotMinTime: "04:00:00",
    slotMaxTime: "24:00:00",
  },
}

const CONST_PROD = {
  GCP_API_KEY: 'AIzaSyDFMcucvSpjcTq7jrXSFmJmX7rxD-ZWg9A',
}

const CONST_DEV = {
  GCP_API_KEY: 'AIzaSyDFMcucvSpjcTq7jrXSFmJmX7rxD-ZWg9A',
}

const CONST = process.env.NODE_ENV == 'development' ? Object.assign(true, {}, CONST_BASE, CONST_DEV) : Object.assign(true, {}, CONST_BASE, CONST_PROD)

export default CONST
