/* unplugin-vue-components disabled */import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "swiper-wrapper"
};
import Swiper, { Manipulation } from 'swiper';
import 'swiper/swiper.min.css'; // import Swiper from 'swiper';
// import 'swiper/swiper.scss';

/**
 * 往前
 * 1/ current idx
 * 2/ target element append idx +1
 * 3/ slide
 */

/**
 * 往後
 * 1/ current idx
 * 2/ target element prepend idx -1
 * 3/ slide
 */

export default {
  __name: 'SlideViews',
  emits: ['init'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var refRoot = ref(null);
    var refSwiper = ref(null);
    var cData = reactive({
      slideIds: []
    });
    var slideHistory = [];
    var slides;
    var swiperInstance;
    onMounted(function () {
      slides = $(refRoot.value).find('.swiper-slide').toArray();
      slides.forEach(function (slide) {
        if (slide.id) {
          cData.slideIds.push(slide.id);
        }
      });
      swiperInstance = new Swiper(refSwiper.value, {
        modules: [Manipulation],
        autoHeight: false,
        speed: 400,
        spaceBetween: 0,
        allowTouchMove: false,
        on: {
          afterInit: function afterInit() {
            $(refRoot.value).on('click', '[data-slide-to]', function () {
              var to = $(this).attr('data-slide-to');
              goTo(to);
            });
            $(refRoot.value).on('click', '[data-back-to]', function () {
              var to = $(this).attr('data-back-to');
              backTo(to);
            });
          },
          activeIndexChange: function activeIndexChange(swiper) {
            slideHistory.push(swiper.activeIndex);
          }
        }
      }); // 先將目標slide放到後一個

      function goTo(identifier) {
        var current = $(refSwiper.value).find('.swiper-slide-active');
        var currentId = current.attr('id');
        var target = $(refSwiper.value).find(identifier);
        var targetIdx = target.index();
        if (!target.length) return;

        if (targetIdx !== current.index() + 1) {
          swiperInstance.removeSlide(targetIdx);
          swiperInstance.addSlide(current.index() + 1, [target[0]]);
        }

        setTimeout(function () {
          slideTo(identifier);
        }, 0);
      } // 先將目標slide放到前一個


      function backTo(identifier) {
        var current = $(refSwiper.value).find('.swiper-slide-active');
        var currentId = current.attr('id');
        var target = $(refSwiper.value).find(identifier);
        var targetIdx = target.index();
        if (!target.length) return;

        if (targetIdx !== current.index() - 1) {
          swiperInstance.removeSlide(targetIdx);
          swiperInstance.addSlide(current.index() - 1, [target[0]]);
        }

        setTimeout(function () {
          slideTo(identifier);
        }, 0);
      } // 呼叫捲動


      function slideTo(identifier) {
        var target = $(refSwiper.value).find(identifier);
        if (!target.length) return;
        var idx = target.index();
        swiperInstance.slideTo(idx);
      } // 取得當前id


      function getCurrentId() {
        return $(refSwiper.value).find('.swiper-slide-active').attr('id');
      }

      nextTick(function () {
        setTimeout(function () {
          var evt = new CustomEvent('resize');
          window.dispatchEvent(evt);
        });
      });
      emit('init', {
        slideTo: slideTo,
        goTo: goTo,
        getCurrentId: getCurrentId,
        slideIds: cData.slideIds
      });
    });
    onUnmounted(function () {
      if (swiperInstance && swiperInstance.destroy) {
        swiperInstance.destroy();
      }
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        ref_key: "refRoot",
        ref: refRoot,
        class: "slide-views"
      }, [_createElementVNode("div", {
        ref_key: "refSwiper",
        ref: refSwiper,
        class: "swiper"
      }, [_createElementVNode("div", _hoisted_1, [_renderSlot(_ctx.$slots, "default")])], 512)], 512);
    };
  }
};