import creteStoreUtil from '@/store/store-util'

const defaultState = {
  data: {}
}

const { init, getData, setData, resetData, initState } = creteStoreUtil({
  defaultState
})

export default {
  namespaced: true,
  state: () => ({
    data: {}
  }),
  actions: {
    init,
  },
  getter: {
    getData
  },
  mutations: {
    initState,
    resetData,
    setData,
  },
}
