import { prop } from "dom7";
import { ref, reactive, onMounted, nextTick } from "vue";
export default {
  props: {
    list: {
      type: Array,
      default: []
    }
  },
  setup: function setup(props) {
    return {};
  }
};