import { computed, reactive } from "vue"
import creteStoreUtil from '@/store/store-util'
import storage, { } from '@/js/storage'
import { Order } from "@/js/services/baseService"
import { EVENTS } from '@/js/test-data'
import store from "@/store/index"
import Router from '@/js/routes'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import { fromApiOrderToOrderEvent } from '@/js/functions'
import mukiConst from '@/js/const'

const moment = extendMoment(Moment)

const defaultState = {
  /**
   * 是否顯示已刪除的預約 (1 => 要包含刪除的預約)
   */
  showDelete: 0,
  /**
   * 只顯示自己是服務人員的event
   */
  onlyShowMyEvent: 0,
  /**
   * 取得不同月周日的事件
   */
  calendarType: '',
  /**
   * 選取的日期
   * @type {string} 日期 - (ISO Sstring)
   */
  selectedDate: (function () {
    var d = new Date()

    d.setHours(0, 0, 0, 0)

    return d.toISOString()
  }()),
  /**
   * 當前瀏覽的年月
   * @type {{month: number, year: number}} 
   */
  currentViewYearMonth: {
    month: null,
    year: null
  },
  /**
   * 當前月份所有事件資料是否已載入
   *@type {Boolean} 
   */
  eventsReady: false,
  /**
   * 當前月份所有事件
   *@type {(OrderEvent|LockEvent)[]} 
   */
  events: [],
  /**
   * 醫師班表
   *@type {DoctorHoursEvent[]} 
   */
  doctorHoursEvents: [
    {
      id: 1,
      hoursType: 0,
      date: '2023-05-11',
      startAt: [10,0],
      endAt: [11,0],
      doctors: ['王曉明']
    },
    {
      id: 2,
      hoursType: 0,
      date: '2023-05-19',
      startAt: [10,0],
      endAt: [11,0],
      doctors: ['王曉明']
    },
    {
      id: 3,
      hoursType: 1,
      date: '2023-05-19',
      startAt: [11,30],
      endAt: [12, 30],
      doctors: ['王曉明']
    },
    {
      id: 4,
      hoursType: 2,
      date: '2023-05-19',
      startAt: [14,0],
      endAt: [15,0],
      doctors: ['王曉明']
    }
  ],
}

const { init, getData, setData, resetData, initState } = creteStoreUtil({
  defaultState
})

/**
 * 修改日工具函式
 * @param {object} state 
 * @param {(event:OrderEvent[])=> OrderEvent[]} mutateFunc - 需要回傳修改後完整的events陣列
 */
function mutateCalendarEvents(state, mutateFunc) {

  if ('function' == typeof mutateFunc) {
    var result = mutateFunc(state.events)
    if (result) state.events = result
  }
}

function copyEvent(array) {
  return array.reduce((acc, cur) => {
    if (cur.lock_target === 1 || cur.lock_target === 3) {
      cur?.lock_employees?.forEach(item => {
        acc.push({
          ...cur,
          consult_id: item.pivot.employee_id,
        })
      })
      cur?.lock_rooms?.forEach(item => {
        acc.push({
          ...cur,
          room_id: item.pivot.branch_room_id,
        })
      })
      return acc
    } 
    acc.push(cur)
    if (
      !(cur.room && Object.keys(cur.room).length) && 
      cur?.assistants?.length
    ) {
      cur.assistants.forEach(item => {
        acc.push({
          ...cur,
          assistantId: item.id,
          assistantColor: item.color,
        })
      })
    }
    return acc
  }, [])
}
let abortController

export default {
  namespaced: true,

  state: () => ({
  }),
  getters: {
    getData,
    // 當前選擇日期格式化顯示
    selectedDateFormat(state) {
      if (!state.selectedDate) return

      return moment(state.selectedDate).format('YYYY/MM/DD')
    },
    // 當前週起始日期
    activeWeekStartDate(state) {
      if (state.selectedDate) {
        var mmSelected = moment(state.selectedDate)
        var firstDayMinus = mmSelected.days() == 0 ? 6 : mmSelected.days() - 1
        var date = mmSelected.subtract(firstDayMinus, 'day')

        date.startOf('day')

        return date.toISOString()
      }
    },
    // 當前週結束日期
    activeWeekEndDate(state, getters) {
      if (getters['activeWeekStartDate']) {
        var activeWeekStartDate = moment(getters['activeWeekStartDate'])
        var date = activeWeekStartDate.add(6, 'day')

        date.endOf('day')

        return date.toISOString()
      }
    },
    // 當前月起始日期
    activeMonthStartDate(state) {
      if (state.selectedDate) {
        var date = moment(state.selectedDate).set('date', 1)

        date.startOf('day')

        return date.toISOString()
      }
    },
    // 當前月結束日期
    activeMonthEndDate(state, getters) {
      if (getters['activeMonthStartDate']) {
        var daysInMonth = moment(state.selectedDate).daysInMonth()
        var date = moment(state.selectedDate).set('date', daysInMonth)

        date.endOf('day')

        return date.toISOString()
      }
    },
    // 一個月
    monthEvents(state, getters, rootState) {
      /** @type {(OrderEvent|LockEvent)[]} */
      var events = copyEvent(state.events)

      return events.filter(event => {
        var myEventFilter = (state.onlyShowMyEvent && !event.hasOwnProperty('lock')) ? (event.consult && event.consult.id == rootState.auth.employeeId) : true
        return myEventFilter
      })
    },
    // 一週
    weekEvents(state, getters, rootState) {
      /** @type {(OrderEvent|LockEvent)[]} */
      var events = copyEvent(state.events)
      var timeStart = getters['activeWeekStartDate']
      var timeEnd = getters['activeWeekEndDate']


      if (!timeStart && !timeEnd) return []

      return events.filter(event => {
        var orderDate = new Date(event.orderDate || event.date || event.contactAt)
        var orderDatemili = orderDate.getTime()
        var startMili = new Date(timeStart).getTime()
        var endMili = new Date(timeEnd).getTime()
        var myEventFilter = state.onlyShowMyEvent && !event.hasOwnProperty('lock') ? (event.consult && event.consult.id == rootState.auth.employeeId) : true

        return orderDatemili >= startMili && orderDatemili <= endMili && myEventFilter
      })
    },
    // 天
    dayEvents(state, getters, rootState) {
      /** @type {(OrderEvent|LockEvent)[]} */
      var events = copyEvent(state.events)

      var timeStart = new Date(state.selectedDate)
      var timeEnd = new Date(state.selectedDate)

      timeStart.setHours(0, 0, 0, 0)
      timeEnd.setHours(23, 59, 59, 999)

      if (!timeStart && !timeEnd) return []

      return events.filter(event => {
        var orderDate = new Date(event.orderDate || event.date || event.contactAt)
        var orderDatemili = orderDate.getTime()
        var startMili = new Date(timeStart).getTime()
        var endMili = new Date(timeStart).getTime()
        var myEventFilter = state.onlyShowMyEvent && !event.hasOwnProperty('lock') ? (event.consult && event.consult.id == rootState.auth.employeeId) : true

        return orderDatemili >= startMili && orderDatemili <= endMili && myEventFilter
      })
    },
    // 當前view，
  },
  actions: {
    init,
    // 取得日曆資料
    getEvents(context, payload) {

      var calendarType = payload?.calendarType || context.state.calendarType
      
      var timeStart
      var timeEnd

      if (calendarType === 'day') {
        timeStart = store.getters['calendar/selectedDateFormat']
        timeEnd = store.getters['calendar/selectedDateFormat']
        context.commit('setData', {
          eventsReady: false
        })
      }else if (calendarType === 'week') {
        timeStart = store.getters['calendar/activeWeekStartDate']
        timeEnd = store.getters['calendar/activeWeekEndDate']
      } else {
        timeStart = store.getters['calendar/activeMonthStartDate']
        timeEnd = store.getters['calendar/activeMonthEndDate']
      }
      if(payload?.calendarType) {
        context.commit('setData', {
          calendarType: calendarType
        })
      }

      if (!timeStart || !timeEnd) return

      if (abortController) {
        abortController.abort()
      }
      abortController = new AbortController()
      context.commit('setData', {
        events: []
      })

      Order.get({
        perPage: 1000,
        page: 1,
        // 0 : 非預約聯繫 1 : 預約聯繫
        // need_to_contact: 0,
        start_at: timeStart,
        end_at: timeEnd,
        show_delete: mukiConst.showDelete.YES,
      },{
        signal: abortController.signal
      }).then((res) => {

        if (res && res.data && Array.isArray(res.data)) {

          /** @type {OrderEvent[]} */
          const orderEventArr = res.data.map(item => fromApiOrderToOrderEvent(item))
          context.commit('setData', {
            eventsReady: true
          })
          context.commit('setData', {
            events: context.state.events.slice().concat(orderEventArr)
          })
        }

      }).finally(() => {
      })

      
      Order.stickyList({
        start_at: timeStart,
        end_at: timeEnd,
        perPage: 150
      },{
        signal: abortController.signal
      }).then((res) => {

        if (res && res.data && Array.isArray(res.data)) {

          const lockEvents = res.data.map(item => {
            return {
              color: item.room ? (item.room ? item.room.color : '') : (item.consult ? item.consult.color : ''),
              memo: item.memo,
              content: item.content,
              consult_id: item.consult_id,
              consult: item.consult,
              id: item.id,
              date: item.order_date,
              startAt: moment(item.start_at).format('HH:mm').split(':').map(strNum => parseInt(strNum)),
              endAt: moment(item.end_at).format('HH:mm').split(':').map(strNum => parseInt(strNum)),
              room_id: item.room?.id,
              room: item.room,
              sticky: 1,
            }
          })

          context.commit('setData', {
            events: context.state.events.slice().concat(lockEvents)
          })

        }

      }).finally(() => {
      })

      if (timeStart && timeEnd) {
        const range = moment.range(timeStart, timeEnd)

        Order.lockList({
          lock_date: Array.from(range.by('day')).map(m => m.format('YYYY-MM-DD'))
        },{
          signal: abortController.signal
        }).then((res) => {

          if (res && Array.isArray(res)) {

            /** @type {LockEvent[]} */
            const lockEvents = res.map(item => {
              return {
                lock_target: item.lock_target,
                lock_employees: item.lock_employees,
                lock_rooms: item.lock_rooms,
                memo: item.memo,

                id: item.id,
                date: item.lock_date,
                startAt: String(item.start_at_format).split(':').map(strNum => parseInt(strNum)),
                endAt: String(item.end_at_format).split(':').map(strNum => parseInt(strNum)),
                lock: 1,
              }
            })

            context.commit('setData', {
              events: context.state.events.slice().concat(lockEvents)
            })

          }

        }).finally(() => {
        })

      }
    },
    // 刪除鎖定事件
    removeLockEvent(context, payload) {

      const eventIds = payload.events

      if (!eventIds || (!Array.isArray(eventIds) && eventIds.length == 0)) {
        console.log('removeLockEvent 請傳入id')
        return
      }

      const tosend = {
        id: eventIds[0]
      }

      Order.lockRemove(tosend).then(res => {
        if (res) {
          context.commit('removeCalendarEvents', {
            calendar: Router.currentRoute.value.name,
            events: eventIds
          })
        }
      })
    }
  },
  mutations: {
    initState,
    resetData,
    setData,
    // 設定當前日曆資料
    setCalendarEvents(state, payload) {
      var events = payload && payload.events

      if (!Array.isArray(events)) {
        console.log('setCalendarEvents events資料需是陣列')
        return
      }

      mutateCalendarEvents(state, (storeEvents) => {
        return events
      })

    },
    // 編輯當前日曆資料
    addCalendarEvents(state, payload) {
      var events = payload && payload.events

      if (!Array.isArray(events)) {
        console.log('addCalendarEvents events資料需是陣列')
        return
      }

      mutateCalendarEvents(state, (storeEvents) => {
        return storeEvents.concat(events)
      })

    },
    // 刪除當前日曆資料
    removeCalendarEvents(state, payload) {
      var eventIds = payload && payload.events

      if (!Array.isArray(eventIds)) {
        console.log('removeCalendarEvents events資料需是陣列')
        return
      }

      mutateCalendarEvents(state, (storeEvents) => {
        return storeEvents.filter(evt => {
          var found = eventIds.find(evtId => evtId == evt.id)
          if (!found) return true
        })
      })

    },
    // 更新日曆資料
    updateCalendarEvents(state, payload) {
      var events = payload && payload.events

      if (!Array.isArray(events)) {
        console.log('updateCalendarEvents events資料需是陣列')
        return
      }

      mutateCalendarEvents(state, (storeEvents) => {
        var cloned = storeEvents.slice()

        // 先移除
        var cleaned = cloned.filter(evt => {
          var found = events.find(evt2 => evt2.id == evt.id && evt2.sticky != 1)
          if (!found) return true
        })

        // 後新增
        var updated = cleaned.concat(events)
        return updated
      })

    },
    // 更新記事資料
    updateStickyCalendarEvents(state, payload) {
      var events = payload && payload.events

      if (!Array.isArray(events)) {
        console.log('updateCalendarEvents events資料需是陣列')
        return
      }
      
      mutateCalendarEvents(state, (storeEvents) => {
        var cloned = storeEvents.slice()

        // 先移除
        var cleaned = cloned.filter(evt => {
          var found = events.find(evt2 => evt2.id == evt.id && evt2.sticky == 1)
          if (!found) return true
        })
        // 後新增
        var updated = cleaned.concat(events)
        return updated
      })

    }
  },
}
