import { createStore, createLogger } from "vuex"
import VuexPersistence from 'vuex-persist'
import creteStoreUtil from '@/store/store-util'
import storage from '@/js/storage'
import modules from "./modules" // 引入modules
import { Post } from "@/js/services/baseService"

const defaultState = {
  /**
   * 延遲訊息
   * @property {Number} id
   * @property {*} type - 種類 (跳轉頁面: goPage)
   * @property {String} expire
   * @property {Object} data
   */
  delayedAction: {},
  appGlobalSpinnerShow: false,

  editPopupShow: false,
  editPopupTarget: '',
  editPopupConfig: {
  },
  editPopupEventData: {},

  addPopupInstance: {},
  addPopupShow: false,
  addPopupTarget: '',
  addPopupConfig: {
  },
  addPopupEventData: {},

  /**
   * 未讀訊息
   */
  newAnnouncements: 0,
  unreadAnnouncements: 0,
  // 主頁預約人員的暫存資料
  tempSelected: {},
  // 警示孤兒名的暫存資料
  tempOrphanSelected: {}
}

const { init, getData, setData, resetData, initState } = creteStoreUtil({
  defaultState
})

const vuexLocal = new VuexPersistence({
  storage: storage,
  asyncStorage: true,
  reducer: (state) => {
    var result = {
      locale: state.locale,
      delayedAction: state.delayedAction,
      auth: {
        token: state.auth.token,
        employeeId: state.auth.employeeId,
        name: state.auth.name,
        brandId: state.auth.brandId,
        branchCode: state.auth.branchCode,
        branchId: state.auth.branchId,
        branches: state.auth.branches,
        loginId: state.auth.loginId,
        memo: state.auth.memo,
        code: state.auth.code,
        level: state.auth.level,
        color: state.auth.color,
        isLogined: state.auth.isLogined,
        limitGroupCode: state.auth.limitGroupCode,
        allPermission: state.auth.allPermission
      },
      tempSelected: state.tempSelected,
      tempOrphanSelected: state.tempOrphanSelected,
    }

    return result
  },
})

const store = createStore({
  modules,
  plugins: process.env.NODE_ENV !== "production" ? [/*createLogger()*/, vuexLocal.plugin] : [vuexLocal.plugin],
  state: () => ({
  }),
  getters: {
    getData,
  },
  actions: {
    // 初始
    async init({ commit }) {

      var psArr = []

      commit('initState')

      Object.keys(modules).forEach(key => {
        psArr.push(store.dispatch(`${key}/init`))
      })

      // 設定語系
      if (!store.state.locale) {
        commit('updateLocale', store.state.locale || 'tw')
      }

      // 等待所有必要資料都準備好
      await Promise.allSettled([...psArr])
    },
    // 更新未讀訊息
    async updateAnnouncements({ commit }) {
      const res = await Post.getNum()
      
      if ( res && res.data ){
        var newUnread = res.data.new
        var unread = res.data.unread

        commit('setData', {
          newAnnouncements: newUnread,
          unreadAnnouncements: unread,
        })
      }
    }
  },
  mutations: {
    setData,
    resetData,
    initState,
    updateLocale(state, locale) {
      if (locale) {
        state.locale = locale
      }
    }
  }
})

export default store
