import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export default {
  __name: 'RoundCloseBtn',
  props: {
    variant: String,
    size: String,
    color: String,
    icon: {
      type: String,
      default: 'icon-close-solid'
    }
  },
  setup: function setup(__props) {
    var props = __props;
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("a", {
        class: _normalizeClass(["round-close-btn", [__props.variant ? "round-close-btn_".concat(__props.variant) : '']]),
        href: "javascript:;",
        style: _normalizeStyle({
          '--round-close-btn-size': __props.size || '',
          '--round-close-btn-color': __props.color || ''
        })
      }, [_createElementVNode("i", {
        class: _normalizeClass(__props.icon)
      }, null, 2)], 6);
    };
  }
};