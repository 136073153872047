import { computed, reactive } from "vue";
import creteStoreUtil from '@/store/store-util';
import storage, { } from '@/js/storage';
import { Employee, Schedule } from "@/js/services/baseService";
import mukiConst from '@/js/const'

const defaultState = {
    list: [
        /**
         * 人員
         * @type {Employee}
         */
        // {
        //     id: 0,
        //     name: '丁小雨',
        //     position: '諮詢師',
        //     roleId: mukiConst.personnelType.CONSULT,
        //     color: '#06799D',
        //     active: false,
        // },
        // {
        //     id: 1,
        //     name: 'Emily',
        //     position: '諮詢師',
        //     roleId: mukiConst.personnelType.CONSULT,
        //     color: '#114EF8',
        //     active: true
        // },
        // {
        //     id: 2,
        //     name: 'Fanny',
        //     position: '諮詢師',
        //     roleId: mukiConst.personnelType.CONSULT,
        //     color: '#B86901',
        //     active: true
        // },
        // {
        //     id: 3,
        //     name: 'Gina',
        //     position: '諮詢師',
        //     roleId: mukiConst.personnelType.CONSULT,
        //     color: '#009486',
        //     active: true
        // },
        // {
        //     id: 4,
        //     name: 'Pinny',
        //     position: '諮詢師',
        //     roleId: mukiConst.personnelType.CONSULT,
        //     color: '#A068AF',
        //     active: true
        // },
        // {
        //     id: 5,
        //     name: '漢米爾頓',
        //     position: '醫生',
        //     roleId: mukiConst.personnelType.DOCTOR,
        //     color: '#95B42F',
        //     active: false
        // },
        // {
        //     id: 6,
        //     name: 'Nancy',
        //     position: '芳療師',
        //     roleId: mukiConst.personnelType.AROMATHERAPIST,
        //     color: '#CE0E2D',
        //     active: false
        // },
        // {
        //     id: 7,
        //     name: 'Org',
        //     position: '護士',
        //     roleId: mukiConst.personnelType.NURSE,
        //     color: '#41AEBF',
        //     active: false
        // },
        // {
        //     id: 8,
        //     name: 'Pinny',
        //     position: '護士',
        //     roleId: mukiConst.personnelType.NURSE,
        //     color: '#465808',
        //     active: true
        // },
    ],
    employeeInBranchReady: false,
    scheduleList: [],
    scheduleListEmployeeList: []
}

const { init, getData, setData, resetData, initState } = creteStoreUtil({
    defaultState
})

export default {
    namespaced: true,

    state: () => ({
    }),
    getters: {
        getData,
    },
    actions: {
        init,
        getEmployee({ commit }) {
            return Employee.employees().then(res => {
                commit('setData', {
                    list: res
                })
            })
        },
        getEmployeeInBranch({ commit }, { target_at, isCome }) {
            if (isCome) {
                return Employee.employeeInBranch({ target_at })
                    .then(res => {
                        commit('setData', {
                            scheduleList: res,
                            scheduleListEmployeeList: res
                        })
                    })
            }
            return Promise.all([
                Employee.employeeInBranch({ target_at, sort_type: 'calendar' }),
                Employee.employeeInBranch({ target_at, sort_type: 'employeeList' })
            ]).then(([scheduleList, scheduleListEmployeeList]) => {
                commit('setData', {
                    scheduleList,
                    scheduleListEmployeeList
                })
            })
        },
    },
    mutations: {
        initState,
        resetData,
        setData,
    },
}
