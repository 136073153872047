/* unplugin-vue-components disabled */import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "page__navbar"
};
var _hoisted_2 = {
  class: "page__content"
};
export default {
  __name: 'Page',
  props: {
    defaultInvisible: Boolean,
    visible: Boolean,
    bottomGotopSpace: Boolean,
    // style
    white: Boolean,
    gray: Boolean
  },
  setup: function setup(__props, _ref) {
    var expose = _ref.expose;
    // 判別使用色系
    var store = useStore();
    var brandId = ref(store.state.auth.brandId);
    var CSSRoot = document.querySelector(':root');

    if (brandId.value === 1) {
      CSSRoot.style.setProperty('--brand-theme-color', '#00A393');
      CSSRoot.style.setProperty('--brand-theme-light-color', '#00a393');
      CSSRoot.style.setProperty('--color-theme-light', '#00a393');
      document.querySelector('body').classList.add('is_DC_brand');
    } else if (brandId.value === 2) {
      CSSRoot.style.setProperty('--brand-theme-color', '#CE0E2D');
      CSSRoot.style.setProperty('--brand-theme-light-color', '#fc8b8b');
      CSSRoot.style.setProperty('--color-theme-light', '#fc8b8b');
      document.querySelector('body').classList.remove('is_DC_brand');
    }

    var refRoot = ref(null);
    var cData = reactive({
      pdLeft: 0
    });
    onMounted(function () {});
    expose({
      refRoot: refRoot
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        ref_key: "refRoot",
        ref: refRoot,
        class: _normalizeClass(["page", [__props.defaultInvisible ? 'page_default-invisible' : '', __props.white ? 'page_white' : '', __props.gray ? 'page_gray' : '', __props.visible ? 'page_visible' : '', __props.bottomGotopSpace ? 'page_bottom-gotop-space' : '']])
      }, [_ctx.$slots['navbar'] ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_renderSlot(_ctx.$slots, "navbar")])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "content")]), _renderSlot(_ctx.$slots, "default")], 2);
    };
  }
};