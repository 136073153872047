// 擴充 easing
window.addEventListener('DOMContentLoaded', function () {
  $.extend($.easing, {
    easeInOutExpo: function easeInOutExpo(x, t, b, c, d) {
      if (t == 0) return b;
      if (t == d) return b + c;
      if ((t /= d / 2) < 1) return c / 2 * Math.pow(2, 10 * (t - 1)) + b;
      return c / 2 * (-Math.pow(2, -10 * --t) + 2) + b;
    }
  });
});