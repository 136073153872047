/* unplugin-vue-components disabled */import { unref as _unref, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { appRwdVal } from '@/js/utils';
export default {
  __name: 'BaseInner',
  props: {
    space: String,
    rwdVal: String
  },
  setup: function setup(__props) {
    var props = __props;
    var rwdStyleValue = ref(null);
    var windowInnerWidth = ref(window.innerWidth);

    function onResize() {
      windowInnerWidth.value = window.innerWidth;
    }

    window.addEventListener('resize', onResize);
    var thisSpace = computed(function () {
      var w = windowInnerWidth.value;

      if (Array.isArray(props.rwdVal) && props.rwdVal.length === 4) {
        return "".concat(appRwdVal(props.rwdVal[0], props.rwdVal[1], props.rwdVal[2], props.rwdVal[3]), "px");
      } else {
        return props.space;
      }
    });
    onUnmounted(function () {
      window.removeEventListener('resize', onResize);
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        class: "base-inner",
        style: _normalizeStyle({
          '--base-inner-space': _unref(thisSpace) ? _unref(thisSpace) : null
        })
      }, [_renderSlot(_ctx.$slots, "default")], 4);
    };
  }
};