/* unplugin-vue-components disabled */import "core-js/modules/es.number.constructor.js";
export default {
  props: {
    num: {
      type: [Number, String],
      default: 0
    },
    bgColor: String,
    size: [String]
  },
  setup: function setup(props, context) {
    var thisNum = computed(function () {
      return parseFloat(props.num);
    });
    return {
      thisNum: thisNum
    };
  }
};