import { computed, ref, onMounted, reactive } from "vue";
export default {
  components: {},
  props: {
    // 實心線
    solidLine: Boolean,
    // no padding
    noPadding: Boolean,
    // padding
    vPadding: [String],
    // tail line
    noTail: Boolean,
    // line color
    lineColor: String,
    // 第一個貼齊靠上
    firstItemAlignTop: Boolean,
    // board style
    board: Boolean,
    // inset
    inset: String
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    return {};
  }
};