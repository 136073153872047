import { Order } from "@/js/services/baseService";
import { debounce } from 'lodash';
export function useContentHints() {
  var store = useStore();
  var brandId = computed(function () {
    return store.state.auth.brandId;
  });
  var keyword = ref('');
  var list = ref([]);
  var popperConfig = {
    placement: 'right-end',
    modifiers: [{
      name: 'preventOverflow'
    }, {
      name: 'flip'
    }, {
      name: 'offset',
      options: {
        offset: [0, 20]
      }
    }]
  };
  var geList = debounce(function () {
    Order.contentHints({
      brand_id: brandId.value,
      keyword: keyword.value
    }).then(function (res) {
      list.value = res;
    });
  }, 500);
  watch(keyword, geList, {
    immediate: true
  });
  return {
    popperConfig: popperConfig,
    keyword: keyword,
    list: list
  };
}